import '@woodmac-lens/styles/scss/vendor/semantic-ui/semantic-ui-compiled.css';
import '@woodmac-lens/styles/scss/index.scss';

import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { LoadingSpinner, history } from '@woodmac-lens/app';
import { ThemeProvider } from '@woodmac-hycb/theme-provider';
import { getTheme } from '@woodmac-hycb/theme-default';

/**
 * Run app in next tick, this ensure that externals are more likely to be loaded
 */
const App = lazy(() => import(/* webpackPreload: true */ './components/App'));

declare let __webpack_nonce__;

// eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
__webpack_nonce__ = window['__nonce__'];

const emotionCache = createCache({
    nonce: window['__nonce__'],
    key: 'lens',
});

// Disable warnings on first-child selector
// @see https://github.com/emotion-js/emotion/issues/1105
emotionCache.compat = true;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Router history={history}>
        <CacheProvider value={emotionCache}>
            <ThemeProvider theme={getTheme()}>
                <Suspense fallback={<LoadingSpinner/>}>
                    <App />
                </Suspense>
            </ThemeProvider>
        </CacheProvider>
    </Router>);
